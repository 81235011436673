import axios from "axios";

export const checkEmail = async (
    clinic: string,
    email: string,
    phone: string,
) => {
    try {
        const { data } = await axios.post(route("check-email", { clinic }), {
            email: email,
            phone_number: phone,
        });
        return data;
    } catch (e) {
        return { error: true };
    }
};

export const checkEmailMultipleInstance = async (
    email: string,
    phone: string,
) => {
    try {
        const { data } = await axios.post(route("check-email-multiple"), {
            email: email,
            phone_number: phone,
        });
        return data;
    } catch (e) {
        return { error: true };
    }
};

export const resendInvite = async (
    clinic: string,
    email?: string | null,
    phone?: string | null,
) => {
    try {
        const { data } = await axios.post(route("resend-invite", { clinic }), {
            email: email,
            phone_number: phone,
        });
        return data;
    } catch (e) {
        return { error: true };
    }
};

export const acceptCommunicationPreference = async (
    clinic: string,
    request: any,
) => {
    try {
        const { data } = await axios.post(
            route("profile.accept-communication-preference", { clinic }),
            request,
        );
        return data;
    } catch (e) {
        return { error: true };
    }
};
